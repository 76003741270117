import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { removeDiacritics } from "common/strings"
import Flatpickr from "react-flatpickr"
import moment from "moment"

const EventProcessForm01 = props => {
  const [event, setEvent] = useState(undefined)
  const [formError, setFormError] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useState(() => {
    setEvent(props.event)
  }, [props.event])

  useEffect(() => {
    if (event !== undefined) {
      if (event.startDate !== undefined) setStartDate(new Date(event.startDate))
      if (event.endDate !== undefined) setEndDate(new Date(event.endDate))
    }
  }, [event])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    setFormError(false)

    props.onComplete({ ...values, startDate, endDate })
  }

  const changeResponsible = event => {
    console.log(event)
  }

  const changeLocation = event => {
    console.log(event)
  }

  return (
    <Fragment>
      <div className="container">
        {formError && (
          <div className="alert alert-danger">
            Por favor verifique que los campos obligatorios no se encuentren
            vacíos.
          </div>
        )}
        <AvForm className="form-horizontal" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="eventName"
                value={event ? event.name : ""}
                label="* Nombre del evento:"
                className="form-control"
                placeholder=""
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="userId"
                value={event ? event.responsibleUserId : 0}
                label="* Responsable / Contacto:"
                className="form-control"
                placeholder=""
                type="select"
                onChange={event => {
                  changeResponsible(event)
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option key={0} value="">
                  -- Seleccione responsable --
                </option>
                {props.users !== undefined &&
                  props.users
                    .sort((a, b) =>
                      removeDiacritics(a.firstName.toLowerCase()) >
                      removeDiacritics(b.firstName.toLowerCase())
                        ? 1
                        : -1
                    )
                    .map((element, index) => (
                      <option value={element.userId} key={element.userId}>
                        {element.firstName} {element.lastName}
                      </option>
                    ))}
              </AvField>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="locationName"
                value={event ? event.locationName : ""}
                label="* Nombre de la sede:"
                className="form-control"
                placeholder=""
                type="text"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              />
            </Col>
          </Row>
          {/*<Row className="mb-4">
            <Col md={12}>
              <AvField
                name="location"
                value={event ? event.locationId : 0}
                label="* Sede:"
                className="form-control"
                placeholder=""
                type="select"
                onChange={event => {
                  changeLocation(event)
                }}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Este campo es requerido",
                  },
                }}
              >
                <option key={0} value="">
                  -- Seleccione sede --
                </option>
                {props.locations
                  .sort((a, b) =>
                    removeDiacritics(a.name.toLowerCase()) >
                    removeDiacritics(b.name.toLowerCase())
                      ? 1
                      : -1
                  )
                  .map((element, index) => (
                    <option value={element.locationId} key={element.locationId}>
                      {element.name} ({element.municipality}, {element.state})
                    </option>
                  ))}
              </AvField>
            </Col>
            <Col md={12}>
              <p className="mt-2">
                ¿No está la sede?{" "}
                <a style={{ color: "rgb(0, 85, 150)" }} onClick={() => {}}>
                  Agregar una nueva
                </a>
              </p>
            </Col>
          </Row>*/}
          <Row className="mb-4">
            <Col xs={12}>
              <label>* Fecha y hora de inicio y fin del evento:</label>
            </Col>
            <Col xl={3} lg={4} md={6} sm={12}>
              <Flatpickr
                name="startDate"
                onChange={date => setStartDate(date[0])}
                value={moment(startDate).format("DD/MM/YYYY HH:mm")}
                className="form-control d-block"
                placeholder="dd/mm/yyyy hh:mm"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y h:i K",
                  dateFormat: "d/m/Y h:i K",
                  enableTime: true,
                }}
              />
            </Col>
            <Col xl={3} lg={4} md={6} sm={12}>
              <Flatpickr
                name="endDate"
                onChange={date => setEndDate(date[0])}
                value={moment(endDate).format("DD/MM/YYYY HH:mm")}
                className="form-control d-block"
                placeholder="dd/mm/yyyy hh:mm"
                options={{
                  altInput: true,
                  altFormat: "d/m/Y h:i K",
                  dateFormat: "d/m/Y h:i K",
                  enableTime: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <div className="col-12 d-flex justify-content-end">
              <Link to="/start/adm-eventos" className="btn btn-light">
                <i className="fa fa-times"></i> Cancelar
              </Link>
              <button type="submit" className="btn btn-primary">
                Avanzar <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </Row>
        </AvForm>
      </div>
    </Fragment>
  )
}

EventProcessForm01.propTypes = {
  event: PropTypes.object,
  onComplete: PropTypes.funct,
  users: PropTypes.array,
  locations: PropTypes.array,
}

export default EventProcessForm01
