import { Card, CardBody } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withRouter } from "react-router-dom/cjs/react-router-dom"

const MAX_WORDS = 300

const EventDetail = props => {
  const singleEvent = props.singleEvent
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (singleEvent.eventImages.length > 0) {
      setImage(singleEvent.eventImages[0].imagePath)
    }
  }, [singleEvent])

  return (
    <Card className="card-eventos">
      <CardBody>
        <div
          className="title"
          onClick={() =>
            props.history.push(`/start/eventos/detalle/${singleEvent.eventId}`)
          }
        >
          {singleEvent.name}
        </div>
        <div className="text-blue mb-2 calendar-text">
          <i className="fa fa-calendar"></i>{" "}
          {moment(singleEvent.startDate).locale("es").format("MMMM DD")}
          {moment(singleEvent.startDate).locale("es").format("YYYY") ===
          moment(singleEvent.endDate).locale("es").format("YYYY")
            ? ""
            : `, ${moment(singleEvent.startDate)
                .locale("es")
                .format("YYYY")}`}{" "}
          a {moment(singleEvent.endDate).locale("es").format("MMMM DD, YYYY")}
        </div>
        <h5 className="">
          <i className="fa fa-map-pin"></i> {singleEvent.locationName}
        </h5>
        <div className="row mb-4 row-image-description">
          <div className="col-12 image">
            <div
              className="image-container"
              onClick={() => {
                props.history.push(
                  `/start/eventos/detalle/${singleEvent.eventId}`
                )
              }}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-8 offset-md-2 col-lg-8 offset-lg-2 col-sm-8 offset-sm-2 col-xs-12 offset-xs-0 d-flex justify-content-center">
            <Link
              className="btn btn-primary btn-rounded btn-block"
              to={`/start/eventos/detalle/${singleEvent.eventId}`}
            >
              <i className="fa fa-external-link-alt"></i> Ver evento
            </Link>
          </div>
        </div>
      </CardBody>
      <style jsx>{`
        .card-eventos {
          min-height: 180px;
          border-radius: 4px;
          background-color: #00559615;
          border-color: #005596;
        }
        div.row-image-description {
          overflow: hidden;
        }
        div.row-image-description > [class*="col-"] {
          margin-bottom: -99999px;
          padding-bottom: 99999px;
        }
        .title {
          font-weight: bold;
          font-size: 18px;
          cursor: pointer;
          line-height: 1;
          margin-bottom: 4px;
          min-height: 40px;
        }
        .image {
          padding: 0 10px;
        }
        .image-container {
          width: 100%;
          aspect-ratio: 8/5;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
        }
        .text-blue {
          color: rgb(49, 98, 156);
        }
        .card-gray {
          background-color: rgb(237, 237, 237);
          border: 1px solid #000;
        }
        .calendar-text {
          font-size: 14px;
        }
      `}</style>
    </Card>
  )
}

EventDetail.propTypes = {
  singleEvent: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(EventDetail)
