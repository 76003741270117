import { Fragment } from "react"
import { withRouter } from "react-router"
import { useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Progress,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import CoursesLessonsDetails from "components/UserCourses/CoursesLessonsDetail"
import {
  getCourseUser,
  getKnowledgeCategories,
  getSkills,
  getLevels,
  getCourseKnowledges,
  getUsers,
  getLessons,
  startCourse,
  progressLesson,
  progressLessonClean,
  getCoursesByUser,
} from "store/actions"
import CourseLessonIntro from "components/Courses/CourseLessonIntro"
import CourseSupportMaterials from "components/Courses/CourseSupportMaterials"
import CourseNotes from "components/Courses/CourseNotes"
import CourseExamUser from "components/UserCourses/CourseExamUser"
import CoursePollForm from "components/UserCourses/CoursePollForm"
import { LoadingIndicator } from "components/Common/LoadingIndicator"

const CourseInfo = props => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState("5")
  const [showLoading, setShowLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const [lessonsProgress, setLessonsProgress] = useState(0)
  const [finishedLessons, setFinishedLessons] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState(undefined)
  const [categoriesSelectedMulti, setCategoriesSelectedMulti] = useState([])
  const [previousCoursesSelectedMulti, setPreviousCoursesSelectedMulti] =
    useState([])
  const [previousCoursesInfo, setPreviousCoursesInfo] = useState([])
  const [responsiblesSelectedMulti, setResponsiblesSelectedMulti] = useState([])
  const [habilitiesSelectedMulti, setHabilitiesSelectedMulti] = useState([])
  const [knowledgesSelectedMulti, setKnowledgesSelectedMulti] = useState([])
  const [suggestedCoursesSelectedMulti, setSuggestedCoursesSelectedMulti] =
    useState([])

  const [courseParticipant, setCourseParticipant] = useState(undefined)

  const {
    course,
    courses,
    knowledgeCategories,
    skills,
    levels,
    users,
    courseKnowledges,
    lessons,
    progressLessons,
    user,
  } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.CoursesList.courses,
    course: store.CourseGet.course,
    knowledgeCategories: store.KnowledgeCategoriesList.knowledgeCategories,
    skills: store.SkillsList.skills,
    levels: store.LevelsList.levels,
    courseKnowledges: store.CourseKnowledgesList.courseKnowledges,
    users: store.UsersList.users,
    lessons: store.LessonsList.lessons,
    progressLessons: store.LessonProgress.lessons,
  }))

  useEffect(() => {
    if (id !== undefined && user != undefined) {
      dispatch(getCoursesByUser(user.idUsuario))
      dispatch(getCourseUser(id, user.idUsuario))
      dispatch(getLessons(id))
      dispatch(progressLesson(id))
      dispatch(startCourse({ idCourse: id }))
    }
    dispatch(getUsers())
    dispatch(getKnowledgeCategories())
    dispatch(getSkills())
    dispatch(getLevels())
    dispatch(getCourseKnowledges())
    return () => {
      dispatch(progressLessonClean())
    }
  }, [id, user])

  useEffect(() => {
    updateCourseParticipant()
  }, [course])

  const knowCat = course?.knowledgeCategories
  const prevCourses = course?.PreviousCourses
  const sugCourses = course?.SuggestedCourses
  const respCourses = course?.ResponsiblesCourses
  const know = course?.knowledges
  const skl = course?.abilities
  const vc = course?.valoracionCurso

  useEffect(() => {
    console.log(prevCourses, courses)
    if (
      prevCourses !== undefined &&
      courses !== undefined &&
      courses.length > 0
    ) {
      setShowLoading(true)
      for (const prevCourse of prevCourses) {
        const previousCourseId = prevCourse.idPreviousCourse
        const previousCourse = courses.find(
          course => course.idCurso === previousCourseId
        )
        if (previousCourse !== undefined) {
          const approved = previousCourse.CourseParticipant?.aproved ?? true
          if (!approved) {
            const exists = previousCoursesInfo.some(
              course => course.idCurso === previousCourse.idCurso
            )
            if (!exists) {
              console.log(previousCoursesInfo)
              setPreviousCoursesInfo([...previousCoursesInfo, previousCourse])
            }
          }
        }
      }
      setShowLoading(false)
    }
  }, [prevCourses, courses])

  useEffect(() => {
    let prevCoursesArray = []
    let sugCoursesArray = []
    let knowCatArray = []
    let knowArray = []
    let sklArray = []
    let rArray = []
    if (respCourses !== undefined)
      respCourses.forEach(respCourse => {
        //const user = users.find(user => user.idUsuario === respCourse.idUser)
        //if (user !== undefined)
        rArray.push({
          label: ``,
          value: respCourses.idUser,
        })
      })

    if (prevCourses !== undefined)
      prevCourses.forEach(prevCourse => {
        prevCoursesArray.push({
          label: `${prevCourse.courseName}`,
          value: prevCourse.idPreviousCourse,
        })
      })

    if (sugCourses !== undefined)
      sugCourses.forEach(sugCourse => {
        sugCoursesArray.push({
          label: `${sugCourse.courseName}`,
          value: sugCourse.idSuggedtedCourse,
        })
      })

    if (knowCat !== undefined)
      knowCat.forEach(knowC => {
        knowCatArray.push({
          label: `${knowC.knowledgeCategory}`,
          value: knowC.idKnowledgeCategory,
        })
      })

    if (know !== undefined)
      know.forEach(knowledge => {
        knowArray.push({
          label: `${knowledge.knowledge}`,
          value: knowledge.idKnowledge,
        })
      })

    if (skl !== undefined)
      skl.forEach(skill => {
        sklArray.push({
          label: `${skill.ability}`,
          value: skill.idAbility,
        })
      })

    setPreviousCoursesSelectedMulti(prevCoursesArray)
    setSuggestedCoursesSelectedMulti(sugCoursesArray)
    setCategoriesSelectedMulti(knowCatArray)
    setKnowledgesSelectedMulti(knowArray)
    setHabilitiesSelectedMulti(sklArray)
    setResponsiblesSelectedMulti(rArray)
  }, [prevCourses, sugCourses, knowCat, vc, users])

  useEffect(() => {
    const progress = getProgress()
    setLessonsProgress(progress)
    return () => {
      setLessonsProgress(0)
    }
  }, [courseParticipant])

  const toggleIconCustom = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleReloadProgress = () => {
    dispatch(getCourseUser(id))
  }

  const updateCourseParticipant = () => {
    const approved = course?.CourseParticipant?.aproved ?? false
    setCourseParticipant(course?.CourseParticipant)
    setApproved(approved)
  }

  const getProgress = () => {
    if (!courseParticipant) return -1
    return !courseParticipant.aproved
      ? lessonsProgress > courseParticipant.advancePercentage
        ? lessonsProgress
        : courseParticipant.advancePercentage
      : 100
  }

  return course !== undefined ? (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {`Curso: ${course.nombreCurso}`} | Capacitación en Línea Fester
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Breadcrumbs
                breadcrumbItems={[
                  {
                    key: "dashboard",
                    title: "Dashboard",
                    link: "/dashboard",
                  },
                  {
                    key: "edit_group",
                    title: course.nombreCurso,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            {showLoading || courses.length <= 0 ? (
              <Col className="col-12 text-center">
                <LoadingIndicator />
              </Col>
            ) : (
              <Col className="col-12">
                <Card>
                  <div className="alert alert-info">
                    <Row>
                      <Col md={9}>
                        <h2 className="mt-4 mb-4" style={{ borderRadius: 0 }}>
                          {course.nombreCurso}
                        </h2>
                      </Col>
                      <Col md={3}>
                        {lessonsProgress >= 0 && (
                          <Fragment>
                            <h5 className="text-center mt-3">
                              Progreso: {lessonsProgress}%
                            </h5>
                            <Progress multi>
                              <Progress
                                bar
                                color="primary"
                                value={lessonsProgress}
                                style={{
                                  width: `${lessonsProgress}%`,
                                }}
                              />
                              <Progress
                                bar
                                value={100 - lessonsProgress}
                                color="light"
                                style={{
                                  width: `${100 - lessonsProgress}%`,
                                }}
                              />
                            </Progress>
                          </Fragment>
                        )}
                      </Col>
                    </Row>
                  </div>

                  {previousCoursesInfo.length > 0 ? (
                    <CardBody>
                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: true,
                            })}
                            onClick={() => {
                              toggleIconCustom("1")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-list"></i> Información
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-list"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={"1"}
                        className="p-3 text-muted mt-4"
                      >
                        <TabPane tabId="1">
                          <div className="alert alert-warning">
                            <h3 className="text-center">
                              Para poder iniciar este curso, es necesario
                              finalizar y aprobar el examen de los cursos
                              previos.
                            </h3>
                          </div>
                          <CourseLessonIntro
                            course={course}
                            knowledgeCategories={categoriesSelectedMulti}
                            previousCourses={previousCoursesSelectedMulti}
                            responsibles={responsiblesSelectedMulti}
                            goNext={undefined}
                            level={
                              levels.find(
                                level => level.idLevel === course.nivelCurso
                              )?.level
                            }
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <Nav className="icon-tab nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "5",
                            })}
                            onClick={() => {
                              toggleIconCustom("5")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-image"></i> Portada
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-image"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleIconCustom("1")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-list"></i> Información
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-list"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleIconCustom("2")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-book-open"></i> Contenido
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-book-open"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "7",
                            })}
                            onClick={() => {
                              toggleIconCustom("7")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-paperclip"></i> Adjuntos
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-paperclip"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleIconCustom("3")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-sticky-note"></i> Notas
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-sticky-note"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "6",
                            })}
                            onClick={() => {
                              toggleIconCustom("6")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-medal"></i> Examen
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-medal"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "4",
                            })}
                            onClick={() => {
                              toggleIconCustom("4")
                            }}
                          >
                            <span className="d-md-none d-lg-block">
                              <i className="fas fa-question-circle"></i>{" "}
                              Valoración
                            </span>
                            <span className="d-none d-md-block d-lg-none">
                              <i className="fas fa-question-circle"></i>
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted mt-4"
                      >
                        <TabPane tabId="5">
                          <Row>
                            <Col md={12}>
                              <img src={course.coverImage} className="w-100" />
                              <br />
                              <div className="d-flex justify-content-center mt-4">
                                <button
                                  onClick={() => setActiveTab("1")}
                                  className="btn btn-secondary ml-2"
                                >
                                  Siguiente{" "}
                                  <i className="fa fa-arrow-right"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="1">
                          <CourseLessonIntro
                            course={course}
                            knowledgeCategories={categoriesSelectedMulti}
                            previousCourses={previousCoursesSelectedMulti}
                            responsibles={responsiblesSelectedMulti}
                            goNext={() => setActiveTab("2")}
                            level={
                              levels.find(
                                level => level.idLevel === course.nivelCurso
                              )?.level
                            }
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <CoursesLessonsDetails
                            course={course}
                            lessons={lessons}
                            progressLessons={progressLessons}
                            activeTab={activeTab}
                            selectedLesson={selectedLesson}
                            cleanSelectedLesson={() =>
                              setSelectedLesson(undefined)
                            }
                            reloadProgress={percentage => {
                              setLessonsProgress(percentage)
                              handleReloadProgress()
                            }}
                            goToExam={() => {
                              setFinishedLessons(true)
                              setActiveTab("6")
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="3">
                          <CourseNotes
                            course={course}
                            goToLesson={idLesson => {
                              setSelectedLesson(idLesson)
                              setActiveTab("2")
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="4">
                          {!approved ? (
                            <div className="alert alert-warning">
                              <h3 className="text-center pt-2">
                                <i className="fa fa-exclamation-circle"></i>{" "}
                                Para poder valorar el curso es necesario haber
                                aprobado el examen.
                              </h3>
                            </div>
                          ) : (
                            <CoursePollForm course={course} />
                          )}
                        </TabPane>
                        <TabPane tabId="6">
                          <CourseExamUser
                            course={course}
                            approved={approved}
                            goToValoration={() => setActiveTab("4")}
                            canApplyExam={
                              (courseParticipant?.advancePercentage ?? 0) >=
                                70 || finishedLessons
                            }
                            reloadProgress={() => handleReloadProgress()}
                          />
                        </TabPane>
                        <TabPane tabId="7">
                          <CourseSupportMaterials
                            course={course}
                            readOnly={true}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  )}
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <div></div>
  )
}

export default withRouter(CourseInfo)
