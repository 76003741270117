import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getUsersByRole, listAllEvents, listLocations } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import moment from "moment"
import EventDetail from "components/Interfester/EventDetail"
import { Link } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const DetalleEvento = props => {
  const { id } = useParams()

  const [event, setEvent] = useState(undefined)
  const [user, setUser] = useState(undefined)
  const [location, setLocation] = useState(undefined)

  const dispatch = useDispatch()

  const { usersByRole, locations, loading, done, error, whoIAm, events } =
    useSelector(state => ({
      whoIAm: state.Profile.user,
      usersByRole: state.UsersList.usersByRole,
      locations: state.LocationsList.locations,
      loading: state.EventUpdate.loading,
      done: state.EventUpdate.done,
      error: state.EventUpdate.error,
      events: state.AllEventsList.events,
    }))

  useEffect(() => {
    dispatch(getUsersByRole(2))
    dispatch(listLocations())
    dispatch(listAllEvents())
  }, [])

  useEffect(() => {
    const event = events.find(event => event.eventId === parseInt(id))
    setEvent(event)
  }, [id, events])

  useEffect(() => {
    if (event !== undefined) {
      const user = usersByRole.find(
        user => user.userId === parseInt(event.responsibleUserId)
      )
      setUser(user)

      const location = locations.find(
        location => location.locationId === parseInt(event.locationId)
      )
      setLocation(location)
    }
  }, [event])

  const copyClipboard = () => {
    const url = document.location.href
    navigator.clipboard.writeText(url)
    alert("Dirección web copiada al portapapeles")
  }

  const nextEvents = events.filter(
    (event, key) => event.eventId !== parseInt(id) && event.status === 1
  )

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Detalle del evento | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Col sm={4}>
                  <Link to="/start/eventos">
                    <i className="fa fa-arrow-left"></i> Regresar
                  </Link>
                </Col>
                <Col sm={8}>
                  <Breadcrumbs
                    breadcrumbItems={[
                      {
                        key: "start",
                        title: "La Academia Fester",
                        link: "/start",
                      },
                      {
                        key: "eventos",
                        title: "Eventos",
                        link: "/start/eventos",
                      },
                      { key: "eventos-detalle", title: "Detalle de evento" },
                    ]}
                  />
                </Col>
              </Row>
              {event !== undefined && (
                <div className="container">
                  <Row>
                    <Col md={12}>
                      <h4 className="text-center mb-0">{event.name}</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md={6}>
                      <Carousel showArrows={true}>
                        {event.eventImages.map((image, index) => (
                          <div key={index}>
                            <img
                              src={image.imagePath}
                              alt="Evento"
                              className="img-responsive"
                            />
                          </div>
                        ))}
                      </Carousel>
                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-rounded pb-1"
                          onClick={copyClipboard}
                        >
                          <i
                            className="fa fa-share-alt"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </button>{" "}
                      </div>
                    </Col>
                    <Col md={6}>
                      <p>
                        <strong>Sede:</strong>{" "}
                        {location !== undefined
                          ? `${location.name} (${location.municipality}, ${location.state}`
                          : event.locationName}
                      </p>
                      <p>
                        <strong>Fecha y hora de inicio del evento:</strong>{" "}
                        {moment(event.startDate).format("DD/MM/YYYY HH:mm")}
                      </p>
                      <p>
                        <strong>Fecha y hora de fin del evento:</strong>{" "}
                        {moment(event.endDate).format("DD/MM/YYYY HH:mm")}
                      </p>
                      <div
                        dangerouslySetInnerHTML={{ __html: event.description }}
                      ></div>
                      <div className="mb-4"></div>
                      {user !== undefined && (
                        <h5>
                          <strong>Mayor información:</strong> {user.firstName}{" "}
                          {user.lastName} -{" "}
                          <a href="mailto:{user.email}">{user.email}</a>
                        </h5>
                      )}
                      <h5>
                        <a target="_blank" href={event.link} rel="noreferrer">
                          <i className="fa fa-link"></i> Sitio web del evento
                        </a>
                      </h5>
                    </Col>
                  </Row>
                  {nextEvents.length > 0 ? (
                    <Fragment>
                      <hr />
                      <Row className="mt-4">
                        <Col sm={4}>
                          <h3>Próximos eventos</h3>
                        </Col>
                        <Row className="mt-4">
                          {nextEvents.map((event, key) => (
                            <Col sm={12} md={4} lg={3} key={key}>
                              <EventDetail singleEvent={event} />
                            </Col>
                          ))}
                        </Row>
                      </Row>
                    </Fragment>
                  ) : (
                    <div className="mb-4"></div>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </Fragment>
  )
}

export default DetalleEvento
