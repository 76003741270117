import { AvField, AvForm } from "availity-reactstrap-validation"
import UploadFileModal from "components/Common/UploadFileModal"
import PropTypes from "prop-types"
import { Fragment, useEffect, useState } from "react"
import { Col, Row, Table } from "reactstrap"
import EventosWysiwig from "./EventosWysiwig"

const EventProcessForm02 = props => {
  const [event, setEvent] = useState(props.event)
  const [formError, setFormError] = useState(false)
  const [images, setImages] = useState([])
  const [description, setDescription] = useState("")
  const [uploadImage, setUploadImage] = useState(false)

  useEffect(() => {
    const imagesFromEvent = []
    if (event.imagePaths) {
      for (let i = 0; i < event.imagePaths.length; i++) {
        imagesFromEvent.push(event.imagePaths[i])
      }
    } else if (event.eventImages) {
      for (let i = 0; i < event.eventImages.length; i++) {
        imagesFromEvent.push(event.eventImages[i].imagePath)
      }
    }
    setImages(imagesFromEvent)
    setDescription(event.description)
  }, [event])

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    if (images.length < 1) {
      setFormError(true)
      return
    }

    setFormError(false)

    props.onComplete({
      ...values,
      imagePaths: images,
      description: description,
    })
  }

  const imageContainer = image => (
    <div className="p2">
      <img src={image} alt="image" className="img-fluid" />
    </div>
  )

  return (
    <Fragment>
      <div className="container">
        {formError && (
          <div className="alert alert-danger">
            Por favor verifique que los campos obligatorios no se encuentren
            vacíos y que haya subido una imagen.
          </div>
        )}
        <AvForm className="form-horizontal" onSubmit={handleSubmit}>
          <Row className="mb-4">
            <Col md={12}>
              <AvField
                name="link"
                value={event.link ?? ""}
                label="Ingrese la URL del evento, si tiene:"
                className="form-control"
                placeholder=""
                type="text"
                validate={{}}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={3}>
              <label>* Imágenes del evento:</label>
            </Col>
            <Col md={9} className="d-flex justify-content-end">
              <button
                className="btn btn-dark"
                type="button"
                onClick={() => setUploadImage(true)}
              >
                <i className="fa fa-upload"></i> Subir imagen
              </button>
            </Col>
            <Col md={12}>
              <Row className="mt-3 mb-3">
                {images.length < 1 && (
                  <div className="text-center alert alert-warning">
                    -- No se han cargado imágenes --
                  </div>
                )}
                {images.map((image, index) => (
                  <Col md={4} key={index}>
                    {imageContainer(image)}
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() =>
                          setImages(images.filter((img, i) => i !== index))
                        }
                      >
                        <i className="fa fa-trash"></i> Eliminar imagen
                      </button>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            {/*<Col md={9}>
              {image !== "" ? (
                <a
                  href={image}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fa fa-file-image"></i> Ver imagen
                </a>
              ) : (event.imagePath ?? "") !== "" ? (
                <a
                  href={event.imagePath}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fa fa-file-image"></i> Ver imagen
                </a>
              ) : (
                <button type="button" className="btn btn-light" disabled={true}>
                  <i className="fa fa-file-image"></i> Sin imagen
                </button>
              )}{" "}
              <button
                className="btn btn-dark"
                type="button"
                onClick={() => setUploadImage(true)}
              >
                <i className="fa fa-upload"></i> Subir imagen
              </button>
            </Col>
            <Col md={12} className="mt-4 mb-4">
              {image !== "" ? (
                imageContainer(image)
              ) : (event.imagePath ?? "") !== "" ? (
                imageContainer(event.imagePath)
              ) : (
                <div></div>
              )}{" "}
            </Col>*/}
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <EventosWysiwig
                thisEvent={event}
                setEditor={e => {
                  setDescription(e)
                }}
              />
            </Col>
          </Row>
          <Row>
            <div className="col-12 d-flex justify-content-end">
              <button onClick={props.onBack} className="btn btn-light">
                <i className="fa fa-arrow-left"></i> Atrás
              </button>
              <button type="submit" className="btn btn-primary">
                Avanzar <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </Row>
        </AvForm>
      </div>
      {uploadImage && (
        <UploadFileModal
          show={true}
          onCancel={() => setUploadImage(false)}
          onConfirm={file => {
            setUploadImage(false)
            setImages([...images, file])
          }}
          image={true}
        />
      )}
    </Fragment>
  )
}

EventProcessForm02.propTypes = {
  onComplete: PropTypes.funct,
  onBack: PropTypes.funct,
  event: PropTypes.object,
}

export default EventProcessForm02
