import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import MyProfile from "pages/Users/MyProfile"

// Help
import Help from "pages/Help/Help"

// Syslog
import Syslog from "pages/syslog/Syslog"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import RestorePassword from "pages/Authentication/RestorePassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Users
import UsersList from "../pages/Users/UsersList"
import AddUser from "../pages/Users/AddUser"
import EditUser from "../pages/Users/EditUser"

// Courses
import CoursesStart from "pages/Courses/CoursesStart"
import CourseDetails from "pages/Courses/CourseDetails"
import CourseInfo from "pages/Courses/CourseInfo"
import CourseIntro from "pages/Courses/CourseIntro"
import MyCourses from "pages/Courses/MyCourses"

// Groups
import GroupsList from "pages/Groups/GroupList"
import AddGroup from "pages/Groups/AddGroup"
import GroupDetails from "pages/Groups/GroupDetails"
import ShowAnalytics from "pages/Analytics/ShowAnalytics"

// Terms and Privacy
import AcceptPrivacyTyC from "pages/Accept-privacy-tyc"

// Catalog
import Catalog from "pages/Catalog"

// Course knowledges
import CourseKnowledgesList from "pages/CourseKnowledges/CourseKnowledgesList"

// Knowledge categories
import KnowledgeCategoriesList from "pages/KnowledgeCategories/KnowledgeCategoryList"

// Businesses
import BusinessesList from "pages/Businesses/BusinessesList"

// Levels

import LevelsList from "pages/Levels/LevelsList"
// Skills
import SkillsList from "pages/Skills/SkillsList"

// Shopping areas
import ShoppingAreasList from "pages/ShoppingAreas/ShoppingAreasList"
import PositionsList from "pages/Positions/PositionsList"
import SupportMaterialTypes from "pages/SupportMaterialTypes/SupportMaterialTypesList"

// Reports
import Reports from "pages/Reports/index"
import Report1 from "pages/Reports/Report1"
import Report2 from "pages/Reports/Report2"
import Report3 from "pages/Reports/Report3"
import Report4 from "pages/Reports/Report4"
import Report5 from "pages/Reports/Report5"
import Report6 from "pages/Reports/Report6"
import ReportA from "pages/Reports/ReportA"
import ReportB from "pages/Reports/ReportB"
import Report9 from "pages/Reports/Report9"
import ReportSurvey from "pages/Reports/ReportSurvey"

// Customers
import CustomersList from "pages/Customers/CustomersList"
import StartFester from "pages/Start/StartFester"
import Editoriales from "pages/Interfester/Editoriales"
import EditorialesList from "pages/Interfester/ListadoEditoriales"
import ConfigurationInterfester from "pages/Interfester/ConfigurationInterfester"
import HubUsersList from "pages/Interfester/HubUsersList"
import Eventos from "pages/Interfester/Eventos"
import Certificados from "pages/Interfester/Certificados"
import ListadoEventos from "pages/Interfester/ListadoEventos"
import AgregarEvento from "pages/Interfester/AgregarEvento"
import EditarEvento from "pages/Interfester/EditarEvento"
import DetalleEvento from "pages/Interfester/DetalleEvento"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/start", component: StartFester },
  { path: "/start/configuration", component: ConfigurationInterfester },
  { path: "/start/editoriales", component: Editoriales },
  { path: "/start/eventos", component: Eventos },
  { path: "/start/eventos/detalle/:id", component: DetalleEvento },
  { path: "/start/certificados", component: Certificados },
  { path: "/start/adm-editoriales", component: EditorialesList },
  { path: "/start/adm-eventos", component: ListadoEventos },
  { path: "/start/adm-eventos/add", component: AgregarEvento },
  { path: "/start/adm-eventos/edit/:id", component: EditarEvento },
  { path: "/start/adm-usuarios", component: HubUsersList },

  // Privacy, Terms and Conditions
  { path: "/accept-privacy-tyc", component: AcceptPrivacyTyC },

  // Users
  { path: "/users", component: UsersList },
  { path: "/users/add", component: AddUser },
  { path: "/users/edit/:id", component: EditUser },

  // Courses
  { path: "/courses", component: CoursesStart },
  { path: "/courses/add", component: CourseDetails },
  { path: "/courses/detail/:id", component: CourseDetails },
  { path: "/courses/info/:id", component: CourseInfo },
  { path: "/courses/intro/:id", component: CourseIntro },
  { path: "/my-courses", component: MyCourses },
  { path: "/my-courses/:tab", component: MyCourses },

  // Groups
  { path: "/groups", component: GroupsList },
  { path: "/groups/add", component: AddGroup },
  { path: "/groups/detail/:id", component: GroupDetails },

  // profile
  { path: "/profile", component: MyProfile },

  // Help
  { path: "/help", component: Help },

  // Help
  { path: "/syslog", component: Syslog },

  // Analytics
  { path: "/analytics", component: ShowAnalytics },

  // Catalog
  { path: "/catalog", component: Catalog },
  { path: "/catalog/businesses", component: BusinessesList },
  { path: "/catalog/shopping-areas", component: ShoppingAreasList },
  { path: "/catalog/positions", component: PositionsList },
  { path: "/catalog/levels", component: LevelsList },
  { path: "/catalog/skills", component: SkillsList },
  { path: "/catalog/knowledges", component: CourseKnowledgesList },
  { path: "/catalog/knowledge-categories", component: KnowledgeCategoriesList },
  { path: "/catalog/support-material-types", component: SupportMaterialTypes },

  // Reports
  { path: "/reports", component: Reports },
  { path: "/reports/report1", component: Report1 },
  { path: "/reports/report2", component: Report2 },
  { path: "/reports/report3", component: Report3 },
  { path: "/reports/report4", component: Report4 },
  { path: "/reports/report5", component: Report5 },
  { path: "/reports/report6", component: Report6 },
  { path: "/reports/reportA", component: ReportA },
  { path: "/reports/reportB", component: ReportB },
  { path: "/reports/report9", component: Report9 },
  { path: "/reports/reportSurvey", component: ReportSurvey },

  // Customers
  { path: "/customers", component: CustomersList },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/restore-password/:token", component: RestorePassword },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
