import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"
import { Dropdown, DropdownToggle } from "reactstrap"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"

// import images
import festerHeader from "../../assets/images/logo_academia_fester_no_margin.png"

//i18n
import { withTranslation } from "react-i18next"

const Header = props => {
  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const isStart = props.isStart

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="navbar-brand-box">
              <img src={festerHeader} alt="Fester" className="img-fluid" />
            </div>

            <span className="app-title">
              {isStart ? "La Academia Fester" : "Capacitación en Línea"}
            </span>

            {
              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    style={{ visibility: "hidden" }}
                    type="text"
                    className="form-control"
                    placeholder="Curso, persona o grupo"
                  />
                  <span
                    className="bx bx-search-alt"
                    style={{ visibility: "hidden" }}
                  />
                </div>
              </form>
            }
          </div>

          <div className="d-flex">
            {/*<div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
                onClick={() => setSearch(!isSearch)}
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.t("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>
            */}

            {/* <div className="dropdown d-none d-lg-inline-block ms-1">
              <Link to="/" className="btn header-item noti-icon mt-2">
                <i className="fa fa-home"></i>
                <br />
                Inicio
              </Link>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Link to="/courses" className="btn header-item noti-icon mt-2">
                <i className="fa fa-book"></i>
                <br />
                Cursos
              </Link>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Link to="/groups" className="btn header-item noti-icon mt-2">
                <i className="fa fa-vector-square"></i>
                <br />
                Grupos
              </Link>
            </div> 

            <div className="dropdown d-none d-lg-inline-block ms-1 mt-3">
              <Link to="/" className="btn header-item noti-icon mt-2">
                <i className="fa fa-question-circle"></i>
              </Link>
            </div>

            <div className="dropdown d-none d-lg-inline-block ms-1 mt-3">
              <Link to="/" className="btn header-item noti-icon mt-2">
                <i className="fa fa-cog"></i>
              </Link>
            </div> 

            <React.Fragment>
              <Dropdown
                isOpen={false}
                toggle={() => {}}
                className="dropdown d-inline-block"
                tag="li"
              >
                <DropdownToggle
                  className="btn header-item noti-icon custom-margin-top"
                  tag="button"
                  id="page-header-notifications-dropdown"
                >
                  <i className="bx bx-help-circle" />
                </DropdownToggle>
              </Dropdown>
            </React.Fragment>*/}

            <NotificationDropdown />

            <ProfileMenu />

            {/*<div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer}
                disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
              </div>*/}
          </div>
        </div>
      </header>
      <style jsx="true">
        {`
          .app-title {
            padding-top: 20px;
            padding-right: 24px;
            font-size: 24px;
          }
          .navbar-brand-box img {
            height: 60px;
            padding-top: 8px;
          }
          @media (max-width: 380px) {
            .navbar-brand-box {
              display: block !important;
            }
            .navbar-brand-box img {
              height: 50px;
              margin-top: 4px;
            }
          }
          @media (max-width: 567px) {
            .app-title {
              display: none;
            }
            .navbar-brand-box {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }
        `}
      </style>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  isStart: PropTypes.bool,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
