import { Fragment, useState, useEffect, useRef } from "react"
import { PropTypes } from "prop-types"
import { Col, Row, Media, Card, CardBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getBlocks,
  startLesson,
  finishLesson,
  startLessonClean,
  finishLessonClean,
  listQuizz,
} from "store/actions"
import Notes from "components/Notes"
import SweetAlert from "react-bootstrap-sweetalert"
import QuizzUserFormModal from "./QuizzUserFormModal"
import moment from "moment"

const sortLessons = unorderedLessons =>
  unorderedLessons.sort((a, b) =>
    a.order === b.order && a.order === 0
      ? a.idLesson > b.idLesson
        ? 1
        : -1
      : a.order > b.order
      ? 1
      : -1
  )

const CoursesLessonsDetails = props => {
  const [selectedLesson, setSelectedLesson] = useState(0)
  const [lastLesson, setLastLesson] = useState(0)
  const [viewQuizz, setViewQuizz] = useState(undefined)
  const [quizzesAnswered, setQuizzesAnswered] = useState(true)

  const lessons = props.lessons ?? []
  const progressLessons = props.progressLessons ?? []

  const dispatch = useDispatch()
  const ref = useRef(null)

  const {
    blocks,
    blocksLoading,
    lessonFinish,
    finishDone,
    finishLoading,
    startDone,
    startLoading,
    quizz,
    quizzLoading,
    quizzDone,
    whoIAm,
  } = useSelector(store => ({
    blocks: store.BlocksList.blocks,
    blocksLoading: store.BlocksList.loading,
    finishDone: store.LessonFinish.done,
    lessonFinish: store.LessonFinish.lesson,
    finishLoading: store.LessonFinish.loading,
    startDone: store.LessonStart.done,
    startLoading: store.LessonStart.loading,
    quizz: store.QuizzList.quizz,
    quizzLoading: store.QuizzList.loading,
    quizzDone: store.QuizzList.done,
    whoIAm: store.Profile.user,
  }))

  useEffect(() => {
    const orderedLessons = sortLessons(lessons)
    const idLesson = orderedLessons[selectedLesson]?.idLesson
    if (idLesson !== undefined) {
      dispatch(getBlocks(idLesson))
    }
    return () => setSelectedLesson(0)
  }, [lessons])

  useEffect(() => {
    if (progressLessons !== undefined) {
      const startedLessons = progressLessons
        .filter(value => value.started)
        .sort((a, b) => (a.startDate > b.startDate ? -1 : 1))
      if (startedLessons.length > 0) {
        const idLesson = startedLessons[0].idLesson
        setLastLesson(idLesson)
      }
    }
  }, [progressLessons])

  useEffect(() => {
    const lessonId = lessons[selectedLesson]?.idLesson
    dispatch(listQuizz(lessonId, whoIAm.idUsuario))
  }, [selectedLesson])

  useEffect(() => {
    const lesson = lessons[selectedLesson]
    if (props.selectedLesson !== undefined) {
      props.cleanSelectedLesson()
      return
    }
    if (lesson !== undefined) {
      setSelectedLesson(selectedLesson)
      dispatch(
        startLesson({
          idCourse: props.course.idCurso,
          idLesson: lesson.idLesson,
        })
      )
      handleChangeLesson(selectedLesson)
    }
  }, [lessons, selectedLesson])

  useEffect(() => {
    if (props.selectedLesson !== undefined || lastLesson !== 0) {
      sortLessons(lessons).map((lesson, index) => {
        if (
          lesson.idLesson === props.selectedLesson ||
          lesson.idLesson === lastLesson
        ) {
          setSelectedLesson(index)
          handleChangeLesson(index)
        }
      })
    }
  }, [props.selectedLesson, lastLesson])

  useEffect(() => {
    setQuizzesAnswered(handleQuizzesAnswered())
  }, [quizz])

  const handleChangeLesson = newLesson => {
    setSelectedLesson(newLesson)
    dispatch(getBlocks(props.lessons[newLesson].idLesson))
    if (ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
      })
  }

  const handleFinishLesson = goToExam => {
    const currentLesson = props.lessons[selectedLesson]
    dispatch(
      finishLesson({
        idCourse: props.course.idCurso,
        idLesson: currentLesson.idLesson,
      })
    )

    if (goToExam) props.goToExam()
  }

  const handleQuizzesAnswered = () => {
    if (quizz !== undefined && quizz.length > 0) {
      const pendingQuizzes = quizz.find(
        element => element.correct === undefined
      )
      return pendingQuizzes === undefined
    }
    return true
  }

  if (startDone) {
    dispatch(startLessonClean())
  }

  if (finishDone) {
    const percentage = lessonFinish.percentage
    props.reloadProgress(percentage)
    dispatch(finishLessonClean())
  }

  if (props.activeTab !== "2") return <div></div>

  return (
    <Fragment>
      <div
        style={{ position: "absolute", top: -50, left: 0, right: 0 }}
        ref={ref}
      ></div>
      <Row>
        <Col md={4} lg={3}>
          <ul className="verti-timeline list-unstyled">
            {lessons
              .sort((a, b) =>
                a.order === b.order && a.order === 0
                  ? a.idLesson > b.idLesson
                    ? 1
                    : -1
                  : a.order > b.order
                  ? 1
                  : -1
              )
              .map((lesson, index) => (
                <li
                  key={`lesson-${index}`}
                  className="event-list"
                  style={{ cursor: "pointer" }}
                >
                  <div className="event-timeline-dot">
                    <i
                      className={`fa fa-circle font-size-18 ${
                        index === selectedLesson
                          ? "alt-celf-color"
                          : index <= selectedLesson
                          ? "alt-celf-color-with-opacity"
                          : "with-opacity"
                      }`}
                    />
                  </div>
                  <Media>
                    <Media body>
                      <h3
                        className={
                          index === selectedLesson
                            ? "alt-celf-color"
                            : index <= selectedLesson
                            ? "alt-celf-color-with-opacity"
                            : "with-opacity"
                        }
                      >
                        {lesson.title}
                      </h3>
                      <span>Lección {index + 1}</span>
                    </Media>
                  </Media>
                </li>
              ))}
          </ul>
          <div className="mt-4">
            <Notes
              course={props.course}
              lesson={props.lessons[selectedLesson]}
            />
          </div>
        </Col>
        <Col md={8} lg={9} className=" bg-gray">
          {blocks !== undefined &&
            blocks.map((block, index) => {
              if (block.type == "text") {
                return (
                  <Row key={`block-${index}`} className="border pt-3 pb-3">
                    <Col>
                      <h3>{props.lessons[selectedLesson].title}</h3>
                      <hr />
                      {blocksLoading && (
                        <div style={{ height: "320px" }}>
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <span style={{ fontSize: "24px" }}>
                              <i className="fa fa-spinner fa-spin"></i> Cargando
                              contenido...
                            </span>
                          </div>
                        </div>
                      )}
                      {blocks !== undefined &&
                        !blocksLoading &&
                        blocks.map((block, index2) => {
                          if (block.type == "text") {
                            return (
                              <Card
                                key={`block-card-${index}-${index2}`}
                                className="card"
                              >
                                <CardBody>
                                  <div
                                    className="table-responsive"
                                    dangerouslySetInnerHTML={{
                                      __html: block.value,
                                    }}
                                  ></div>
                                </CardBody>
                              </Card>
                            )
                          }
                        })}
                      {quizz !== undefined && quizz.length > 0 && (
                        <Fragment>
                          <hr />
                          <h3 className="mt-4 mb-2">
                            Refuerza tu conocimiento de esta lección
                            respondiendo a las siguientes preguntas.
                          </h3>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Pregunta</th>
                                <th>Respuesta</th>
                                <th>Fecha</th>
                                <th>Ver</th>
                              </tr>
                            </thead>
                            <tbody>
                              {quizz?.map(singleQuizz => (
                                <tr key={`tr-quizz-${singleQuizz.quizzId}`}>
                                  <td>{singleQuizz.question}</td>
                                  <td className="text-center">
                                    {singleQuizz.correct === undefined ? (
                                      <span
                                        title="Pendiente"
                                        className="text-warning"
                                      >
                                        <i className="fa fa-clock"></i>
                                      </span>
                                    ) : singleQuizz.correct ? (
                                      <span
                                        title="Correcta"
                                        className="text-success"
                                      >
                                        <i className="fa fa-check"></i>
                                      </span>
                                    ) : (
                                      <span
                                        title="Incorrecta"
                                        className="text-danger"
                                      >
                                        <i className="fa fa-times"></i>
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {singleQuizz.registerUserDate === undefined
                                      ? "-"
                                      : moment(
                                          singleQuizz.registerUserDate
                                        ).format("D/MM/YYYY")}
                                  </td>
                                  <td className="text-center">
                                    <button
                                      className="btn btn-primary btn-block"
                                      onClick={() => setViewQuizz(singleQuizz)}
                                    >
                                      <i className="fa fa-eye" /> Ver
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                )
              }
              if (block.tyoe == "youtube") {
                var video_id = block.value.split("v=")[1]
                var ampersandPosition = video_id.indexOf("&")
                if (ampersandPosition != -1) {
                  video_id = video_id.substring(0, ampersandPosition)
                }
                return (
                  <iframe
                    key={`block-${index}`}
                    width="100%"
                    height="480"
                    src={`https://www.youtube.com/embed/${video_id}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                )
              }
            })}
          {!quizzesAnswered && (
            <div className="mt-2 mb-2 alert alert-warning">
              Por favor responda todas las respuestas para continuar a la
              siguiente lección.
            </div>
          )}
          <div
            className={`mt-4 mb-4 d-flex justify-content-center ${
              startLoading || finishLoading ? "d-none" : ""
            }`}
          >
            {selectedLesson > 0 && (
              <button
                onClick={() => handleChangeLesson(selectedLesson - 1)}
                className="btn btn-light mr-2"
              >
                <i className="fa fa-arrow-left"></i> Lección anterior
              </button>
            )}
            &nbsp;&nbsp;&nbsp;
            {props.lessons.length > selectedLesson + 1 && (
              <button
                onClick={() => {
                  handleFinishLesson(false)
                  handleChangeLesson(selectedLesson + 1)
                }}
                className={`btn btn-secondary ml-2 ${
                  quizzesAnswered ? "" : "disabled"
                }`}
              >
                Siguiente lección <i className="fa fa-arrow-right"></i>
              </button>
            )}
            {props.lessons.length == selectedLesson + 1 && (
              <button
                onClick={() => handleFinishLesson(true)}
                className={`btn btn-secondary ml-2 ${
                  quizzesAnswered ? "" : "disabled"
                }`}
              >
                Ir al examen <i className="fa fa-medal"></i>
              </button>
            )}
          </div>
        </Col>
        <style jsx="true">{`
          .bg-gray {
            background-color: #f9f9f9;
          }
        `}</style>
      </Row>
      <QuizzUserFormModal
        quizz={viewQuizz}
        course={props.course}
        onAnswer={() => {
          const lessonId = lessons[selectedLesson]?.idLesson
          dispatch(listQuizz(lessonId, whoIAm.idUsuario))
        }}
        onClose={() => setViewQuizz(undefined)}
      />
      {lastLesson !== 0 && props.activeTab === "2" && (
        <SweetAlert
          title="¿Desea continuar donde se quedó?"
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Continuar aquí"
          onConfirm={() => {
            setLastLesson(0)
          }}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="Regresar al inicio"
          onCancel={() => {
            setLastLesson(0)
            handleChangeLesson(0)
          }}
        >
          <p>
            ¿Desea continuar el curso donde se quedó la última vez que ingresó
            al curso o quiere iniciar al inicio?
          </p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

CoursesLessonsDetails.propTypes = {
  course: PropTypes.object,
  lessons: PropTypes.array,
  progressLessons: PropTypes.array,
  selectedLesson: PropTypes.number,
  cleanSelectedLesson: PropTypes.func,
  reloadProgress: PropTypes.func,
  goToExam: PropTypes.func,
  activeTab: PropTypes.string,
}

export default CoursesLessonsDetails
